import $ from 'jquery';

$(window).scroll(function() {
  var scroll = $(window).scrollTop();

  if (scroll >= 200) {
      $(".banner").addClass("banner-sticky");
  } else {
      $(".banner").removeClass("banner-sticky");
  }
});
