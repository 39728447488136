import $ from 'jquery';

$('.nav-primary .menu-item').on('mouseenter', function(){
  if(!$('body').hasClass('nav-open')){
    $(this).addClass('hover');
  }
});

$('.nav-primary .menu-item').on('mouseleave', function(){
  if(!$('body').hasClass('nav-open')){
    $(this).removeClass('hover');
  }
});

$(document).ready(function(){
  let dropdowns = document.querySelectorAll('.dropdown-toggle');
  dropdowns.forEach((dd)=>{
    dd.addEventListener('click', function () {
      var el = this.nextElementSibling;
      el.style.display = el.style.display==='block'?'none':'block';
    });
  });
});

function getMenuWidth() {
  let myDuration = this.offsetWidth / 250;
  document.documentElement.style.setProperty('--after-width', this.offsetWidth.toString() + "px");
  document.documentElement.style.setProperty('--after-duration', myDuration.toString() + "s");
}

// On crée des écouteurs d'événement pour les titres du menu
function createAddEventListers() {
  let myTitles = document.getElementsByClassName("menu-item");
  if (myTitles.length > 0) {
    for (const element of myTitles) {
      element.addEventListener('mouseover', getMenuWidth);
    }
  }

}

window.addEventListener('load', createAddEventListers);
