import $ from 'jquery';

$(document).ready(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $('.scrollup').fadeIn(1000);
    } else {
      $('.scrollup').fadeOut(1000);
    }
  });

  $(document).on('click', '.scrollup', function () {
    $('html, body').animate({ scrollTop: 0 }, 500);
    return false;
  });
});
