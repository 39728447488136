import $ from 'jquery';

import {
  Chart,
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js';

Chart.register(
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

$(document).ready(function () {

  // Electricity Chart
  $.ajax({
    url: '/wp-json/wp/v2/electricity/?orderby=electricity_date&per_page=100',
    method: 'GET',
    success: function (electricity) {
      var dates = [];
      var prices = [];

      var slice = 0;
      if (window.matchMedia("screen and (min-width: 768px)").matches) {
        slice = 10;
      } else {
        slice = 5;
      }
      electricity.slice(-slice).forEach(e => {
        var dateObj = new Date(e['metadata']['_crb_electricity_date']);
        var month = dateObj.toLocaleString('default', { month: 'short' });
        var year = dateObj.getUTCFullYear();
        var newdate = month + "-" + year.toString().substr(-2);

        dates.push(newdate);
        prices.push(e['metadata']['_crb_electricity_price']);
      });

      prices = [].concat.apply([], prices);

      if (document.getElementById('electricityChart')) {
        if (electricity.length !== 0 && prices.length !== 0) {
          const data = {
            labels: dates,
            datasets: [{
              /*global labelYear*/
              /*eslint no-undef: "error"*/
              label: labelYear,
              backgroundColor: '#e12f12',
              borderColor: '#e12f12',
              data: prices,
            }],
          };

          const config = {
            type: 'line',
            data: data,
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
              scales: {
                x: {
                  weight: 10,
                  display: true,
                  title: {
                    display: true,
                    padding: {
                      top: 20,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                  },
                  grid: {
                    lineWidth: 3,
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    /*global captionYear*/
                    /*eslint no-undef: "error"*/
                    text: captionYear,
                    color: '#e12f12',
                    font: {
                      family: 'Montserrat',
                      size: 20,
                      style: 'normal',
                      lineHeight: 1.2,
                      weight: 900,
                    },
                    padding: {
                      top: 30,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                  },
                  grid: {
                    color: '#3b3554',
                    lineWidth: 1,
                  },
                },
              },
              events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'], //to disable onclick event on legend
              borderWidth: 5,
            },
          };
          $('#electricityChart').wrap("<div class='energynews-table'></div>");
          new Chart(document.getElementById('electricityChart'), config);
        } else {
          $('#electricityChart').hide();
        }
      }
    },
  });

  // gasChart Chart
  $.ajax({
    url: '/wp-json/wp/v2/gas/?orderby=gas_date&per_page=100',
    method: 'GET',
    success: function (gas) {
      var dates = [];
      var prices = [];

      var slice = 0;
      if (window.matchMedia("screen and (min-width: 768px)").matches) {
        slice = 10;
      } else {
        slice = 5;
      }
      // foreach elements present in the results of the ajax request to get all informations from the BO
      gas.slice(-slice).forEach(e => {
        var dateObj = new Date(e['metadata']['_crb_gas_date']);
        var month = dateObj.toLocaleString('default', { month: 'short' });
        var year = dateObj.getUTCFullYear();
        var newdate = month + "-" + year.toString().substr(-2);

        dates.push(newdate);
        prices.push(e['metadata']['_crb_gas_price']);
      });

      prices = [].concat.apply([], prices);

      if (document.getElementById('gasChart')) {
        if (gas.length !== 0 && prices.length !== 0) {
          const data = {
            labels: dates,
            datasets: [{
              /*global labelMonth*/
              /*eslint no-undef: "error"*/
              label: labelMonth,
              backgroundColor: '#717171',
              borderColor: '#717171',
              data: prices,
            }],
          };

          const config = {
            type: 'line',
            data: data,
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                    padding: {
                      top: 20,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                  },
                  grid: {
                    lineWidth: 3,
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                    /*global captionMonth*/
                    /*eslint no-undef: "error"*/
                    text: captionMonth,
                    color: '#717171',
                    font: {
                      family: 'Montserrat',
                      size: 20,
                      style: 'normal',
                      lineHeight: 1.2,
                      weight: 900,
                    },
                    padding: {
                      top: 30,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    },
                  },
                  grid: {
                    color: '#3b3554',
                    lineWidth: 1,
                  },
                },
              },
              events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'], //to disable onclick event on legend
              borderWidth: 5,
            },
          };
          $('#gasChart').wrap("<div class='energynews-table'></div>");
          new Chart(document.getElementById('gasChart'), config);
        } else {
          $('#gasChart').hide();
        }
      }
    },
  });
});
